import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/colors'
import { bp } from '../styles/colors'

const FilterSection = (props) => {
    const [ open, setOpen ] = useState(false)

    const [ activeCategory, setActive] = useState("Kaikki")

    return (
        <CenteringWrapper>
        <Wrapper>
        <FilterWrapper>
              <FilterList isOpen={open}>
                { props.categories && props.categories.map( n => (
                    <FilterElem
                    onClick={() => {
                        setActive(n)
                        props.onClick(n)
                        setOpen(false)
                    }}
                    active={
                        activeCategory === n ? "true" : "false"
                    }
                    >
                        {n}
                    </FilterElem>
                ))}
              </FilterList>
        </FilterWrapper>
        </Wrapper>
        </CenteringWrapper>
    )
}

const FilterElem = styled.li`
    color: ${colors.black};
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${(props) => (props.active === "true" ? colors.grey6 : "transparent")};
    padding: 0.8rem 1.3rem;
    border-radius: 2rem;
    -webkit-tap-highlight-color: transparent;
    font-size: 1.1rem;

    &:hover{
       background-color: ${colors.grey6};
    }

    @media screen and (max-width: ${bp.mobile}) {
        &:hover{
       background-color: none;
        }
    }

    &:active{
        background-color: ${colors.grey6};
    }
`

const FilterList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 500px;
    list-style: none;
    align-items: center;
    transition: 0.2s;
    z-index: 9;
    margin: 0 auto;
    align-self: flex-start;
    gap: 1rem;
    overflow: scroll;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 

    ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
   

    @media screen and (max-width: ${bp.mobile}) {
       gap: 0.5rem;
       justify-content: flex-start;
      
    }
`

const FilterWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    max-width: 500px;
    width: 500px;
    overflow: hidden;
`

const Wrapper = styled.section`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    max-width: ${bp.large};

    @media screen and (max-width: ${bp.mobile}) {   
        justify-content: center;
}
`

const CenteringWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: -3rem 5rem 5rem 5rem;

    @media screen and (max-width: ${bp.mobile}) {   
        margin: -1rem 1rem 3rem 1rem;
        justify-content: center;
}

`

export default FilterSection