import React, { useEffect } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import ProjectPopup from "../components/projectPopup";
import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { bp } from "../styles/colors";
import ItemCard from "../components/itemCard";
import FilterSection from "../components/filterSection";
import ProjectHero from "../components/projectHero";
import { SmallMediumTitle } from "../styles/fonts";
import SectionTitle from "../components/sectionTitle";
import Seo from "../components/Seo";

const VuokrauspalveluPage = ({ location }) => {
    const [popOpen, setPopOpen] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(null);

    if (typeof window !== "undefined" && window.document) {
        if (popOpen !== null) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }

    const itemData = useStaticQuery(graphql`
        query Item {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "item" } } }
            ) {
                nodes {
                    frontmatter {
                        itemDesc
                        title
                        listElements {
                            listElement
                        }
                        smallImg3 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        smallImg2 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        bigImg {
                            childImageSharp {
                                fluid(maxWidth: 300, maxHeight: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        shortItemDesc
                        category
                    }
                }
            }
            image: file(relativePath: { eq: "square/Ylitalo-19.jpg" }) {
                publicURL
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const getItemImages = (item) => {
        const data = item.frontmatter;
        const images = [];

        if (data.bigImg) {
            images.push(data.bigImg);
        }

        if (data.smallImg2) {
            images.push(data.smallImg2);
        }

        if (data.smallImg3) {
            images.push(data.smallImg3);
        }

        return images;
    };

    const itemList = itemData.allMarkdownRemark.nodes;

    const categories = ["Kaikki", "Työkalut", "Ilmanvaihto", "Muut"];

    //Change category
    const changeCategory = (cat) => {
        if (cat === "Kaikki") {
            setItemsToShow(itemList);
            return;
        }

        const toShow = itemList.filter(
            (n) => n.frontmatter.category.toLowerCase() === cat.toLowerCase()
        );
        setItemsToShow(toShow);
    };

    useEffect(() => {
        setItemsToShow(itemList);
    }, [itemList]);

    return (
        <Layout>
            <Seo
                title="Vuokraamo | Ylitalon"
                description="Työkalut, peräkärryt, sekä ilmankuivaimet. Vuokraamme monipuolista kalustoamme joustavasti."
                keywords={[
                    "työkalujen vuokraus, työkaluvuokraus",
                    "Lahti",
                    "Lahden talousalue",
                    "Porvoo",
                    "Orimattila",
                    "Hollola",
                ]}
                url="https://ylitalon.netlify.app/vuokrauspalvelu"
                image={
                    "https://ylitalon.netlify.app" + itemData.image.publicURL
                }
            />
            <ProjectHero
                bigTitle="Vuokraus"
                mediumTitle="Vuokraamme laadukasta kalustoa joustavasti"
                smallTitle="Työkalut, peräkärryt, sekä ilmankuivaimet"
            />

            <TitleContainer>
                <Cont>
                    <SectionTitle title="Vuokraus"></SectionTitle>
                </Cont>
            </TitleContainer>

            <FilterSection onClick={changeCategory} categories={categories} />

            {itemsToShow &&
                itemsToShow.filter(
                    (n) => n.frontmatter.category.toLowerCase() === "työkalut"
                ).length !== 0 && (
                    <>
                        <MidHead>Työkalut</MidHead>
                        <ProjectShow>
                            <ProjectShowWrapper>
                                {/**PRINT ALL PROJECTS*/}
                                {itemsToShow &&
                                    itemsToShow
                                        .filter(
                                            (n) =>
                                                n.frontmatter.category.toLowerCase() ===
                                                "työkalut"
                                        )
                                        .map((n) => (
                                            <ItemCard
                                                id={n.id}
                                                onClick={(e) => {
                                                    setPopOpen(n);
                                                }}
                                                fluidImg={
                                                    n.frontmatter.bigImg !==
                                                        null &&
                                                    n.frontmatter.bigImg
                                                        .childImageSharp.fluid
                                                }
                                                title={n.frontmatter.title}
                                                projectDesv={
                                                    n.frontmatter.shortItemDesc
                                                }
                                            ></ItemCard>
                                        ))}
                            </ProjectShowWrapper>
                        </ProjectShow>
                    </>
                )}

            {itemsToShow &&
                itemsToShow.filter(
                    (n) =>
                        n.frontmatter.category.toLowerCase() === "ilmanvaihto"
                ).length !== 0 && (
                    <>
                        <MidHead>Ilmanvaihto</MidHead>
                        <ProjectShow>
                            <ProjectShowWrapper>
                                {itemsToShow &&
                                    itemsToShow
                                        .filter(
                                            (n) =>
                                                n.frontmatter.category.toLowerCase() ===
                                                "ilmanvaihto"
                                        )
                                        .map((n) => (
                                            <ItemCard
                                                id={n.id}
                                                onClick={(e) => {
                                                    setPopOpen(n);
                                                }}
                                                fluidImg={
                                                    n.frontmatter.bigImg !==
                                                        null &&
                                                    n.frontmatter.bigImg
                                                        .childImageSharp.fluid
                                                }
                                                title={n.frontmatter.title}
                                                projectDesv={
                                                    n.frontmatter.shortItemDesc
                                                }
                                            ></ItemCard>
                                        ))}
                            </ProjectShowWrapper>
                        </ProjectShow>
                    </>
                )}

            {itemsToShow &&
                itemsToShow.filter(
                    (n) => n.frontmatter.category.toLowerCase() === "muut"
                ).length !== 0 && (
                    <>
                        <MidHead>Muut</MidHead>
                        <ProjectShow>
                            <ProjectShowWrapper>
                                {itemsToShow &&
                                    itemsToShow
                                        .filter(
                                            (n) =>
                                                n.frontmatter.category.toLowerCase() ===
                                                "muut"
                                        )
                                        .map((n) => (
                                            <ItemCard
                                                id={n.id}
                                                onClick={(e) => {
                                                    setPopOpen(n);
                                                }}
                                                fluidImg={
                                                    n.frontmatter.bigImg !==
                                                        null &&
                                                    n.frontmatter.bigImg
                                                        .childImageSharp.fluid
                                                }
                                                title={n.frontmatter.title}
                                                projectDesv={
                                                    n.frontmatter.shortItemDesc
                                                }
                                            ></ItemCard>
                                        ))}
                            </ProjectShowWrapper>
                        </ProjectShow>
                    </>
                )}

            {
                /**POP UP HANDLER*/
                popOpen && (
                    <ProjectPopup
                        data-type-="popupElem"
                        smallTitle={popOpen.frontmatter.category}
                        medTitle={popOpen.frontmatter.title}
                        smallDesc={popOpen.frontmatter.shortItemDesc}
                        location="Lisätiedot"
                        listElements={[...popOpen.frontmatter.listElements]}
                        description={popOpen.frontmatter.itemDesc}
                        images={getItemImages(popOpen)}
                        price="200€ / vrk"
                        buttonText="vuokraus"
                        onClick={() => setPopOpen(null)}
                        id={popOpen.id}
                    />
                )
            }
        </Layout>
    );
};

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 5rem 0 5rem;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 2rem 1rem 0 1rem;
    }
`;

const Cont = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: ${bp.large};
`;

const MidHead = styled(SmallMediumTitle)`
    width: 100%;
    text-align: center;
    padding: 0rem 0;

    @media screen and (max-width: ${bp.mobile}) {
        padding: 1rem 0 0 0;
    }
`;

const ProjectShowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6rem 4rem;
    max-width: ${bp.large};
    width: 100%;
    padding: 3rem 0 6rem 0;

    @media screen and (max-width: ${bp.mobile}) {
        gap: 3rem 3rem;
    }
`;

const ProjectShow = styled.section`
    justify-content: center;
    display: flex;
    margin: 1rem 5rem 5rem 5rem;

    @media screen and (max-width: ${bp.mobile}) {
        padding: 0;
        margin: 0 1rem 0 1rem;
    }
`;

export default VuokrauspalveluPage;
